.footerbar{
    width:100%;
    height:6rem;
    bottom:0;
    background-color: lavender;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.footerbar ul{
    margin:0;
    list-style: none;
    display:flex;
    flex-direction: row;
}

li img{
    width:3rem;
    height:3rem;
    padding-right:1rem;
}