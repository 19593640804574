.aboutmecontainer{
    border: 1px solid black;
    height:100vh;
    display: flex;
    flex-direction: column;
}

.aboutmecontent{
    margin:7.5rem;
    margin-top: 12.5rem;
}

.aboutmeheader{
    font-size: 2.5rem;
    margin:0;
}

h3{
    color: gray;
}

