.projectscontainer{
    display: flex;
    height:100vh;
    width: 100%;
    align-items: center;
}

.scrollingsection{
    height:50vh;
    width:100%;
    display: flex;
    /* border:1px solid black; */
    justify-content: space-evenly;
    align-items: center;
}

.scrollingsection div{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scrollingsection img{
    height:80%;
    width:100%;
    border-radius: 1rem;
}

.scrollingsection p{

}

