.introductioncontainer{
    position: absolute;
    /* border: 1px solid black; */
    width:100%;
    height:100%;
    justify-content:center;
    align-items: center;
    display:flex;
    z-index: -1;
}

.intro{
    width: 28%;
    margin-right: 2.5rem;
    /* border: 1px solid black; */
}

.intro h1{
    font-size:1.6rem;
    /* border: 1px solid black; */
}

.intro h3{
    color:gray;
}

.portrait{
    height: 65%;
    /* border: 1px solid black; */
    margin-left:2.5rem;
    margin-bottom:7.5rem;
}