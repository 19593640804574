.navbar{
    position: absolute;
    display:grid; 
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
    align-items: center;
    height: 8rem;
    font-size:1.5rem;
    width:100%;
    /* border:1px solid black; */
}

.nav4{
    font-weight: bold;
}

.navbar a{
    text-decoration: none;
    color: inherit;
}

.navbar a:hover{
    transition: 0.3s;
    color:lavender;
}